import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { ApolloClient } from '@apollo/client';
import { navigate } from 'gatsby';
import { jwtDecode } from 'jwt-decode';
import { setSelectedFacility } from 'src/apollo/local-state';
import { LTA_LOGOUT } from 'src/components/lta-memberships/lta-memberships-queries';
import { getClientConfig, getEnvConfig, getRootProviderId } from 'src/config/config';
import sentrySetup from 'src/config/sentry-setup';
import { HEADER_ROOT_PROVIDER_ID } from 'src/constants/auth-headers';
import { logout as Logout, logoutVariables as LogoutVariables } from 'src/graphql-types/lta-registration/logout';
import * as classic from 'src/utils/classic-api';
import {
  clearAuthStorage,
  retrieveDefaultFacility,
  retrieveLTAToken,
  retrieveToken,
  retrieveUser,
  setSectionsAndDistricts,
  storeDefaultFacility,
  storeToken,
  storeUser,
  storeUsername,
  storeUserVenues,
} from 'src/utils/storage/local-storage';
import { getBaseUrl } from 'src/utils/url-management';

import authService from './AuthService';
import { StaffScope, SystemRole, VenueSystemRole } from './enums';
import { makeCsTokenHeader } from './headers';
import { isBrowser, LoginError, LoginProps, UserVenue } from './types/auth.types';

let loginAttempts = 0;
const MAX_LOGIN_ATTEMPTS = 5;
const ATTEMPT_RESET_TIMEOUT = 60000;

interface AuthContextProps {
  user: any | null;
  login: (props: LoginProps) => Promise<{ errorCode: LoginError }>;
  logOut: (client?: ApolloClient<any>) => void;
  resetSession: (client?: ApolloClient<any>, redirectUrl?: string) => void;
  getToken: () => string | null;
  token: string | null;
  setToken: (token: string | null) => void;
  ltaLogout: () => void;
  getAuthUrls: () => { signIn: string; signOut: string };
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<any | null>(retrieveUser());
  const [token, setTokenState] = useState<string | null>(null);

  useEffect(() => {
    if (isBrowser) {
      process.env.NODE_ENV !== 'development' && sentrySetup();
      const isLogin = window.location.pathname === '/login/';
      !isLogin && authPing();
    }
  }, []);

  const setToken = (newToken: string | null) => {
    setTokenState(newToken);
    storeToken(newToken as string);
    authService.setToken(newToken);
  };

  const login = async ({ client, redirectUrl }: LoginProps): Promise<{ errorCode: LoginError }> => {
    if (loginAttempts >= MAX_LOGIN_ATTEMPTS) {
      return { errorCode: LoginError.TOO_MANY_ATTEMPTS };
    }

    const errorCode = await fetchUserInfo(client);
    if (errorCode !== LoginError.NONE) {
      clearAuthStorage();
      loginAttempts++;
      setTimeout(() => {
        loginAttempts = 0;
      }, ATTEMPT_RESET_TIMEOUT);
      return { errorCode };
    }

    try {
      await fetchAndStoreToken();
    } catch {
      return { errorCode: LoginError.GENERIC };
    }

    loginAttempts = 0;

    const currentUrl = window.location.pathname + window.location.search;
    if (redirectUrl && currentUrl === redirectUrl) {
      return { errorCode: LoginError.NONE };
    }

    navigate(redirectUrl ?? '/');
    return { errorCode };
  };

  const fetchAndStoreToken = async () => {
    const tokenRes = await fetch(`${getEnvConfig().CLUBSPARK_CLASSIC_URL}/Account/Tokens?clientId=clubspark-app`, {
      credentials: 'include',
      headers: getClientConfig().useTenantSso
        ? {
            [HEADER_ROOT_PROVIDER_ID]: getRootProviderId(),
          }
        : undefined,
    });
    const token = tokenRes.headers.get('x-api-token');
    if (!token) throw Error('No token');
    setToken(token);
  };

  const logOut = (client?: ApolloClient<any>) => {
    if (!isBrowser) return;

    const useLtaLogout = getClientConfig().useLtaLogout;
    if (useLtaLogout) {
      ltaLogout(client);
    } else {
      clearAuthStorage();
      client?.clearStore();
      window.location.href = getAuthUrls().signOut;
    }
    setUser(null);
    setToken(null);
    authService.resetAuth();
    clearAuthStorage();
  };

  const getAuthUrls = () => {
    const origin = isBrowser ? window.location.origin : '';
    const accountUrl = `${getEnvConfig().CLUBSPARK_CLASSIC_URL}/Account`;
    const signInReturnUrl = encodeURIComponent(`${origin}/login?auto=1`);
    const signOutReturnUrl = encodeURIComponent(`${origin}/login`);
    const sourceParam = getClientConfig().useTenantSso ? `&sourceID=${getRootProviderId()}` : '';
    return {
      signIn: `${accountUrl}/SignIn?returnUrl=${signInReturnUrl}${sourceParam}`,
      signOut: `${accountUrl}/SignOut?returnUrl=${signOutReturnUrl}${sourceParam}`,
    };
  };

  const resetSession = (client?: ApolloClient<any>, redirectUrl?: string) => {
    if (isBrowser) {
      resetUserSession(client, redirectUrl);
    }
  };

  const getToken = () => authService.getToken();

  const ltaLogout = (client?: ApolloClient<any>) => {
    const authCode = retrieveLTAToken() || '';

    client?.query<Logout, LogoutVariables>({ query: LTA_LOGOUT, variables: { authCode } }).finally(() => {
      clearAuthStorage();
      client?.clearStore();

      window.location.href = getLtaSignOutUrl();
    });
  };

  const getLtaSignOutUrl = () => {
    const returnUrl = `${getBaseUrl()}/login`;

    return `${getEnvConfig().CLUBSPARK_CLASSIC_URL}/Account/SignOut?returnUrl=${encodeURIComponent(returnUrl)}&source=ra`;
  };

  const fetchUserInfo = async (client: ApolloClient<object>): Promise<LoginError> => {
    const isSaaS = getClientConfig().isSaaS;
    const [userError, venuesError] = await Promise.all([
      fetchCurrentUser(),
      ...(!isSaaS ? [fetchUserVenues(client)] : []),
    ]);
    if (userError !== LoginError.NONE) return userError;

    if (venuesError !== LoginError.NONE && !isSaaS) {
      // Here we check for the global system admin role of super admin/admin who can access any venue
      if ((venuesError === LoginError.INVALID_SCOPE || venuesError === LoginError.NON_ADMIN) && userIsGlobalAdmin()) {
        // There must be a selected facility. For global admin that is not a venue admin default to National
        const national = await classic.fetchNationalVenue();
        if (national) {
          const facility = { Name: national.Name, VenueID: national.ID };
          setSelectedFacility(facility, client);
          storeDefaultFacility(facility);
          return LoginError.NONE;
        }
        return LoginError.GENERIC;
      }
      return venuesError;
    }

    return LoginError.NONE;
  };

  const fetchCurrentUser = async () => {
    try {
      const res = await classic.fetchCurrentUser();
      if (res.status === 401) return LoginError.UNAUTHENTICATED;
      const user = await res.json();
      storeUser(user);
      if (user?.FirstName && user?.LastName) storeUsername(`${user.FirstName} ${user.LastName}`);
      return LoginError.NONE;
    } catch {
      return LoginError.GENERIC;
    }
  };

  const fetchUserVenues = async (client: ApolloClient<object>): Promise<LoginError> => {
    try {
      const res = await classic.fetchUserVenues();

      if (res.status === 401) {
        return LoginError.UNAUTHENTICATED;
      }
      const venues: UserVenue[] = (await res.json())?.Venues ?? [];

      setSectionsAndDistricts();

      const { validVenues: adminVenues, hasValidVenueWithNoScopes } = validateUserVenues(venues, {
        ignoreScopes: !!getClientConfig().ignoreScopes,
      });

      if (!adminVenues?.length) {
        return hasValidVenueWithNoScopes ? LoginError.INVALID_SCOPE : LoginError.NON_ADMIN;
      } else {
        storeUserVenues(adminVenues);

        // Ensure the logged in user is an admin at the browser-stored default facility
        const currentDefaultId = retrieveDefaultFacility()?.VenueID;
        const saveDefault = adminVenues.find((v) => v.VenueID === currentDefaultId) ?? adminVenues[0];
        storeDefaultFacility(saveDefault); // may have updated
        setSelectedFacility(saveDefault, client);
      }
      return LoginError.NONE;
    } catch (e) {
      console.log('fetch user venues error', e);
      return LoginError.GENERIC;
    }
  };

  const validateUserVenues = (venues: UserVenue[], options: { ignoreScopes: boolean }) => {
    // In order to log in a user must meet the following criteria:
    //  - Have a valid module scope (e.g. tournaments/staff/ranking) for a venue AND either
    //     - have an admin or superadmin scope at the venue OR
    //     - have an admin or superadmin venue system role at the venue (for backwards compat)
    const hasAdminRole = (role: VenueSystemRole) =>
      [VenueSystemRole.ADMINISTRATOR, VenueSystemRole.SUPER_ADMINISTRATOR].some((ar) => role & ar);

    const hasAdminScope = (scope: StaffScope) => [StaffScope.ADMIN, StaffScope.SUPERADMIN].some((as) => scope & as);

    const hasModuleScope = (scope: StaffScope) =>
      [
        StaffScope.TOURNAMENTS,
        StaffScope.RANKINGS,
        StaffScope.REPORTS,
        StaffScope.STAFF,
        StaffScope.PLAYERS,
        StaffScope.PLAYTRACKER,
        StaffScope.SUSPENSIONS,
        StaffScope.MEMBERSHIPS,
        StaffScope.ORGANISATIONS,
        StaffScope.PROGRAMMES,
        StaffScope.EVENTS,
        StaffScope.COACHES,
        StaffScope.LEAGUES,
        StaffScope.AREAS,
      ].some((ms) => scope & ms);

    const { ignoreScopes } = options;
    let hasValidVenueWithNoScopes = false;

    const validVenues = venues?.filter?.((v) => {
      if (ignoreScopes) {
        const role: VenueSystemRole = v.VenueSystemRoles ?? VenueSystemRole.NONE;
        return hasAdminRole(role);
      }

      const scope: StaffScope = v.Scope ?? StaffScope.NONE;

      // The new way, all in scopes
      if (hasAdminScope(scope) && hasModuleScope(scope)) {
        // you need admin/superadmin AND a module scope
        return true;
      } else if (hasAdminScope(scope)) {
        hasValidVenueWithNoScopes = true;
      }

      return false;
    });

    return { validVenues, hasValidVenueWithNoScopes };
  };

  const userIsGlobalAdmin = () => {
    const userSystemRoles = retrieveUser()?.SystemRoles ?? SystemRole.NONE;
    return !!(userSystemRoles & SystemRole.SUPER_ADMINISTRATOR || userSystemRoles & SystemRole.ADMINISTRATOR);
  };

  return (
    <AuthContext.Provider
      value={{ user, login, logOut, resetSession, getToken, setToken, token, getAuthUrls, ltaLogout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  if (!isBrowser) return {} as AuthContextProps;
  const context = useContext(AuthContext);
  if (!context) throw new Error('useAuth must be used within an AuthProvider');
  return context;
};

export const authPing = async () => {
  const token = retrieveToken();
  const isTokenValid = token && (await tokenIsValid(token));
  if (!isTokenValid) {
    resetUserSession();
  }
};

export const resetUserSession = (client?: ApolloClient<any>, redirectUrl?: string) => {
  if (isBrowser) {
    clearAuthStorage();
    client?.clearStore();
    navigate('/login', { state: { redirectUrl } });
  }
};

export const userIsGlobalAdmin = () => {
  if (isBrowser) {
    const userSystemRoles = retrieveUser()?.SystemRoles ?? SystemRole.NONE;
    return !!(userSystemRoles & SystemRole.SUPER_ADMINISTRATOR || userSystemRoles & SystemRole.ADMINISTRATOR);
  }
};

export const tokenIsValid = async (token: string): Promise<boolean> => {
  if (tokenIsExpired(token)) return Promise.resolve(false);
  return !(await tokenIsIlligitimate(token));
};

export const tokenIsExpired = (token: string): boolean => {
  try {
    const decoded: { exp: number } = jwtDecode(token);
    if (!decoded.exp) return true;
    return decoded.exp * 1000 < Date.now();
  } catch (e) {
    return true; // If we can't decode the token, assume it's expired
  }
};

/**
 * Leverages the token based auth automatic fallback in the saas core authorizer
 * to check if the token is still valid whilst omitting the cookie credentials.
 */
const tokenIsIlligitimate = async (token: string): Promise<boolean> => {
  const res = await classic.fetchWithAuth(
    `${getEnvConfig().KUBE_BASE_URL}/saas-core-authorizer-api/v2/User/GetCurrentUser`,
    {
      credentials: 'omit',
      headers: {
        Authorization: makeCsTokenHeader(token),
      },
    },
  );
  return res?.status === 401;
};
