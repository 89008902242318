import { NATIONAL_SECTION_ID } from 'src/constants/orgId';
import { PackageYear } from 'src/graphql-types/lta-registration/globalTypes';
import { fetchRegionVenues } from 'src/utils/classic-api';

const isBrowser = typeof window !== 'undefined';

export const LTA_ACCESS_TOKEN = 'lta_auth_token';
export const CLUBSPARK_TOKEN = 'cs_auth_token';
export const CLUBSPARK_USERNAME = 'cs_auth_username';
const DEFAULT_FACILITY = 'cs_default_facility';
export const USER_VENUES = 'cs_user_venues';
export const CLUBSPARK_USER = 'cs_user';
const LTA_USER = 'lta_user';
const ROWS_PER_PAGE = 'cs_rows_per_page';
const SECTIONS = 'cs_sections';
const DISTRICTS = 'cs_districts';
const PLAYER_SEARCH_FILTERS = 'cs_player_search_filters';
const COLUMN_PREFERENCE = 'cs_column_preference';
const setColumnPreferenceId = (id: string) => `${COLUMN_PREFERENCE}_${id}`;
const RANKING_FILTERS = 'cs_ranking_filters';
const PLAYER_SEARCH_FILTER = 'cs_player_search_filter';
const SELECTED_YEAR = 'selected_year';
const TEAM_RANKINGS_FILTERS = 'cs_team_rankings_filters';

export const storeLTAToken = (token: string) => {
  isBrowser && localStorage.setItem(LTA_ACCESS_TOKEN, token);
};

export const retrieveLTAToken = () => {
  return isBrowser ? localStorage.getItem(LTA_ACCESS_TOKEN) : null;
};

export const storeToken = (token: string) => {
  isBrowser && localStorage.setItem(CLUBSPARK_TOKEN, token);
};

export const retrieveToken = () => {
  return isBrowser ? localStorage.getItem(CLUBSPARK_TOKEN) : null;
};

export const storeUser = (user: any) => {
  isBrowser && localStorage.setItem(CLUBSPARK_USER, JSON.stringify(user));
};

export const retrieveUser = () => {
  const user = isBrowser && localStorage.getItem(CLUBSPARK_USER);
  return user ? JSON.parse(user) : null;
};

export const storeLTAUser = (user: any) => {
  isBrowser && localStorage.setItem(LTA_USER, JSON.stringify(user));
};

export const retrieveLTAUser = () => {
  const user = isBrowser && localStorage.getItem(LTA_USER);
  return user ? JSON.parse(user) : null;
};

export const storeUsername = (username: string) => {
  isBrowser && localStorage.setItem(CLUBSPARK_USERNAME, username);
};

export const retrieveUsername = () => {
  return isBrowser ? localStorage.getItem(CLUBSPARK_USERNAME) : null;
};

export const storeDefaultFacility = (fac: any) => {
  isBrowser && localStorage.setItem(DEFAULT_FACILITY, JSON.stringify(fac));
};

export const retrieveDefaultFacility = () => {
  const fac = isBrowser && localStorage.getItem(DEFAULT_FACILITY);
  return fac ? JSON.parse(fac) : null;
};

export const storeUserVenues = (venues: any[]) => {
  isBrowser && localStorage.setItem(USER_VENUES, JSON.stringify(venues));
};

export const retrieveUserVenues = () => {
  const venues = isBrowser && localStorage.getItem(USER_VENUES);
  return venues ? JSON.parse(venues) : null;
};

export const storeRowsPerPage = (rowsPerPage: number, tableId?: string) => {
  const storageKey = tableId ? `${ROWS_PER_PAGE}-${tableId}` : ROWS_PER_PAGE;
  isBrowser && localStorage.setItem(storageKey, rowsPerPage.toString());
};

export const retrieveRowsPerPage = (tableId?: string) => {
  const storageKey = tableId ? `${ROWS_PER_PAGE}-${tableId}` : ROWS_PER_PAGE;
  const rpp = isBrowser && localStorage.getItem(storageKey);
  return rpp ? +rpp : null;
};

export const clearStorage = () => {
  const lsKeys = [
    CLUBSPARK_TOKEN,
    LTA_ACCESS_TOKEN,
    CLUBSPARK_USERNAME,
    USER_VENUES,
    CLUBSPARK_USER,
    SECTIONS,
    DISTRICTS,
    PLAYER_SEARCH_FILTERS,
    DEFAULT_FACILITY,
  ];
  if (isBrowser) lsKeys.forEach((key) => localStorage.removeItem(key));
};

export const clearAuthStorage = () => {
  const lsKeys = [CLUBSPARK_TOKEN, LTA_ACCESS_TOKEN, CLUBSPARK_USERNAME, USER_VENUES, CLUBSPARK_USER, DEFAULT_FACILITY];
  if (isBrowser) lsKeys.forEach((key) => localStorage.removeItem(key));
};

export const setSectionsAndDistricts = async () => {
  const venues = await fetchRegionVenues();
  const sectionList = venues.filter((x) => x.GroupID === NATIONAL_SECTION_ID);
  const districtList = venues.filter((x) => x.GroupID !== NATIONAL_SECTION_ID);
  if (isBrowser) {
    localStorage.setItem(SECTIONS, JSON.stringify(sectionList));
    localStorage.setItem(DISTRICTS, JSON.stringify(districtList));
  }
};

export const getSectionsAndDistricts = () => {
  const storedSections = isBrowser && localStorage.getItem(SECTIONS);
  const storedDistricts = isBrowser && localStorage.getItem(DISTRICTS);

  return {
    sections: storedSections ? JSON.parse(storedSections) : undefined,
    districts: storedDistricts ? JSON.parse(storedDistricts) : undefined,
  };
};

export const setPlayerSearchFilters = (filter) => {
  isBrowser && localStorage.setItem(PLAYER_SEARCH_FILTERS, JSON.stringify(filter));
};

export const getPlayerSearchFilters = () => {
  const filter = isBrowser && localStorage.getItem(PLAYER_SEARCH_FILTERS);
  return filter ? JSON.parse(filter) : null;
};

export const storeColumnPreference = (id: string, data: any) => {
  isBrowser && localStorage.setItem(setColumnPreferenceId(id), JSON.stringify(data));
};

export const retrieveColumnPreference = (id: string) => {
  const preference = isBrowser && localStorage.getItem(setColumnPreferenceId(id));
  return preference ? JSON.parse(preference) : null;
};

export const storeRankingFilters = (filters: Record<string, string>) => {
  isBrowser && localStorage.setItem(RANKING_FILTERS, JSON.stringify(filters));
};

export const retrieveRankingFilters = () => {
  const filters = isBrowser && localStorage.getItem(RANKING_FILTERS);
  return filters ? JSON.parse(filters) : null;
};

export const storePlayerPointsSeachFilter = (search: string) => {
  isBrowser && localStorage.setItem(PLAYER_SEARCH_FILTER, search);
};

export const retrievePlayerPointsSeachFilter = () => {
  const search = isBrowser && localStorage.getItem(PLAYER_SEARCH_FILTER);
  return search || null;
};

export const storeSelectedYear = (year: string) => {
  isBrowser && localStorage.setItem(SELECTED_YEAR, year);
};

export const retrieveSelectedYear = () => {
  const year = isBrowser && localStorage.getItem(SELECTED_YEAR);
  return year || PackageYear.YEAR_2022_2023;
};

export const storeTeamRankingsFilters = (filters: Record<any, any>) => {
  isBrowser && localStorage.setItem(TEAM_RANKINGS_FILTERS, JSON.stringify(filters));
};

export const retrieveTeamRankingsFilters = () => {
  const filters = isBrowser && localStorage.getItem(TEAM_RANKINGS_FILTERS);
  return filters ? JSON.parse(filters) : null;
};
