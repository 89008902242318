import { ChecklistType } from 'src/components/checklists/types';
import { EQUALITY_OPERATOR, SCHEME_COURSE_STATUS_MAP, WEEKDAY_TYPE_MAP } from 'src/utils/constants/classic';

export enum CoachingCourseType {
  SINGLE = 'Single',
  COURSE = 'Course',
  RECURRING = 'Recurring',
  SERIES = 'Series',
}

export enum SchemeCourseExtendedStatus {
  COMPLETED = 'Completed',
  IN_PROGRESS = 'InProgress',
  UPCOMING = 'Upcoming',
  INACTIVE = 'Inactive',
  CANCELLED = 'Cancelled',
  RESTRICTED = 'Restricted',
}

export enum ChecklistStatus {
  NOT_STARTED = 0,
  INCOMPLETE = 1,
  AWAITING_REVIEW = 2,
  COMPLETE = 3,
  REJECTED = 4,
}

export type ProgrammeStatus = keyof typeof SCHEME_COURSE_STATUS_MAP;
export type Weekday = keyof typeof WEEKDAY_TYPE_MAP;

export enum ClassicQuery {
  SearchOrganisationSchemes = 'SearchOrganisationSchemes',
  GetOrganisationSchemeCourses = 'GetOrganisationSchemeCourses',
}

type FilterOperator = (typeof EQUALITY_OPERATOR)[keyof typeof EQUALITY_OPERATOR];

export interface ClassicQueryFilter {
  Property: string;
  Values: any[];
  Operator: FilterOperator;
}
export interface VenueAdmin {
  ID: string;
  UserID: string;
  ExternalID: string;
  FirstName: string;
  LastName: string;
  EmailAddress: string;
  Scope: number;
  VenueRoles: [];
  SystemRoles: {
    Value: number;
    Name: string;
  }[];
  InvitationExpiryDateTime?: string;
  CreatedDateTime: string;
}

export interface SchemeCourseContact {
  FirstName: string;
  ID: string;
  LastName: string;
  VenueContactID: string;
}

export interface SchemeCourseVenue {
  Address1: string;
  ConsentOneText: string;
  ID: string;
  Name: string;
  Postcode: string;
  Town: string;
  RegionID: string;
  County: string;
}

export interface SchemeCourseSession {
  Activities: any[];
  Capacity: number;
  Category: number;
  Contacts: SchemeCourseContact[];
  Cost: number;
  EndDateTime: string;
  Gender: number;
  ID: string;
  IsCourseBookingSession: boolean;
  StartDateTime: string;
  Status: number;
  Theme: number;
  TotalAttendees: number;
  TotalPaid: number;
  TotalParticipants: number;
  TotalRated: number;
  TotalRating: number;
  Venue: SchemeCourseVenue;
}

type SchemeOptionMap = {
  [ClassicQuery.GetOrganisationSchemeCourses]: {
    Features: number;
    NumberOfSessions: number;
    Themes: any[];
    WaitingListLevel: number;
  };
  [ClassicQuery.SearchOrganisationSchemes]: {
    DefaultContactEmailAddress: string;
    DefaultContactName: string;
    DefaultContactPhoneNumber: string;
    NumberOfCourses: number;
  };
};

export type Scheme<T extends ClassicQuery> = T extends keyof SchemeOptionMap
  ? SchemeOptionMap[T] & {
      ID: string;
      Category: number;
      Name: string;
      Programme: number;
      Status: number;
      SubCategory: number;
      Venue: SchemeCourseVenue;
      MinimumAge: number;
      MaximumAge: number;
    }
  : never;

export interface SchemeCourse {
  ID: string;
  AdminUrl: string;
  Availability: number;
  Code: string;
  ContactName: string;
  PrimaryContact: PrimaryContact;
  ContactEmailAddress: string;
  ContactPhoneNumber: string;
  Contacts: SchemeCourseContact[];
  CourseType: CoachingCourseType;
  DatesAndTimes: any[];
  DefaultCapacity: number;
  DefaultCost: number;
  DefaultDuration: number;
  DefaultName: string;
  DefaultTime: number;
  DefaultVenue: SchemeCourseVenue;
  DiscountPercentage: number;
  DiscountSessions: number;
  DisplayAddNewSession: boolean;
  ExtendedStatus: SchemeCourseExtendedStatus;
  Gender: number;
  IsDeleted: boolean;
  LeadCoach: string;
  MaximumAge: number;
  MinimumAge: number;
  Name: string;
  NumberOfVisibleSessions: number;
  RollingSessionsEnabled: boolean;
  Scheme: Scheme<ClassicQuery.GetOrganisationSchemeCourses>;
  Sessions: SchemeCourseSession[];
  StartDate: string;
  Status: ProgrammeStatus;
  TotalAttendees?: number;
  TotalParticipants: number;
  TotalSessions: number;
  WaitingContacts: any[];
  WaitingListManual: boolean;
  WaitingListStatus: number;
  WeekDays: Weekday[];
}

export interface PrimaryContact {
  ID: string;
  VenueContactID: string;
  FirstName: string;
  LastName: string;
}

enum VenueRoleCategory {
  Responsibility = 'Responsibility',
  Membership = 'Membership',
}

export enum VenueRoleSubCategory {
  NONE = 'None',
  COACHING_TEAM = 'CoachingTeam',
  MENTAL_HEALTH_CHAMPION = 'MentalHealthChampion',
  STAFF = 'Staff',
}

export enum VenueRoleStatus {
  ACTIVE = 'Active',
}

export enum VenueContactStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  ARCHIVED = 'Archived',
  DELETED = 'Deleted',
}

interface VenueRole {
  Category: VenueRoleCategory;
  Data: any;
  Name: string;
  Status: VenueRoleStatus;
  SubCategory: VenueRoleSubCategory;
  Venue: {
    ID: string;
    Name: string;
    RegionID: string;
  };
}

export interface Accreditation {
  Label: string;
  Name: string;
  ExpiryDate: string;
  CompletionDate: string;
  IsValid: boolean;
  IsExpired: boolean;
}

export interface ContactRegion {
  ID: string;
  Name: string;
}

export interface Coach {
  ID: string;
  UserID?: string;
  FirstName: string;
  LastName: string;
  EmailAddress: string;
  BirthDate: string;
  Age: number;
  Gender?: string;
  ExternalData?: any;
  Status: VenueContactStatus;
  Invited: boolean;
  Registered: boolean;
  ContactRoles: VenueRole[];
  ContactRegions: ContactRegion[];
  ContactAccreditations: { Accreditations: Accreditation[] };
}

export enum EntityType {
  ORGANISATION = 0,
  USER = 1,
}

export interface TenantChecklist {
  Tag: string;
  ApprovalType: number;
  EntityType: EntityType;
  Name: string;
  ItemTags: string[];
}

interface ChecklistVenue {
  ID: string;
  FriendlyID: string;
  Name: string;
  County: string;
}

export interface VenueChecklist {
  Venue: ChecklistVenue;
  ChecklistState: {
    Checklist: TenantChecklist;
    Status: ChecklistStatus;
    Note?: string;
  };
  ChecklistItemStates: ChecklistItem[];
}

export interface UserChecklist {
  User: {
    ID: string;
    Name: string;
    Venues: ChecklistVenue[];
  };
  ChecklistState: {
    Checklist: TenantChecklist;
    Status: ChecklistStatus;
    Note?: string;
  };
  ChecklistItemStates: ChecklistItem[];
}

type ChecklistTypesMap = {
  [ChecklistType.USER]: UserChecklist;
  [ChecklistType.ORGANISATION]: VenueChecklist;
};

export type Checklist<T extends ChecklistType> = ChecklistTypesMap[T];

export interface ChecklistItem {
  ChecklistItem: {
    Name: string;
    ApprovalType: number;
    Tag: string;
  };
  Status: ChecklistStatus;
  Note?: string;
  File?: {
    Name: string;
    Url: string;
    Size?: number;
  };
}

export enum ChecklistApprovalType {
  AUTO = 0,
  MANUAL = 1,
}

interface Condition {
  ID: string;
  OptedOut: boolean;
}

interface UserVenue {
  ID: string;
  VenueContactID: string;
  Category: number;
  Classification: number;
  VenueName: string;
  UrlSegment: string;
  Status: number;
  HasDomain: boolean;
}
export interface VenueContact {
  ID: string;
  AuthorityContactID: string;
  VenueID: string;
  UserID: string;
  Index: number;
  FirstName: string;
  LastName: string;
  EmailAddress: string;
  PhoneNumber: string;
  IsFemale: number;
  IsJunior: boolean;
  BirthDate: string;
  FirstAidLevel: number;
  Postcode: string;
  AddressNotSet: boolean;
  PrivatePIN: string;
  PublicPIN: string;
  AccountProvider: number;
  AccountProviderKey: string;
  Status: number;
  Registered: boolean;
  Unsubscribed: boolean;
  ConsentOne: boolean;
  ConsentTwo: boolean;
  ConsentThree: boolean;
  ConsentFour: boolean;
  Memberships: [];
  VenueRoles: {
    ID: string;
    Name: string;
    Category: number;
    SubCategory: number;
  }[];
  Tags: [];
  VenueSystemRoles: number[];
  VenueSystemNotifications: [];
  Venues: UserVenue[];
  AuthorityConditions: Condition[];
  VenueConditions: Condition[];
  ContactRelationships: [];
  ContactRelationshipsInverse: [];
  IsAdministratorWithinAuthority: boolean;
  IsMemberWithinAuthority: boolean;
  IsMembershipContactWithinAuthority: boolean;
  CanBeEmailed: boolean;
  SystemRoles: number;
  Scope: number;
  Consents: [];
  InvitationUrl?: string;
}

export interface Discount {
  id: string;
  accountingEntityId: string;
  amount: number;
  codeCount: number | null;
  createdDateTime: string;
  criteria: any;
  expiresDateTime: string | null;
  maxTotalRedemptions: number | null;
  maxValue: number | null;
  name: string;
  numberOfUses: number;
  promoCode: string | null;
  startsDateTime: string | null;
  usageLimitReached: boolean;
}

export interface DiscountRedemption {
  id: string;
  accountingEntityId: string | null;
  accountingEntityIds: (string | null)[];
  discountId: string;
  discountName: string;
  discountPromoCode: string;
  discountStartsDateTime: string | null;
  discountExpiresDateTime: string | null;
  discountUsageLimitReached: boolean;
  customerId: string;
  customerName: string;
  customerGender: string;
  customerAge: number;
  dateUsed: string;
  amount: number;
  code: string;
  singleUseCode: string | null;
  itemId: string | null;
  itemName: string;
  itemType: string;
  itemModule: string;
  customData: {
    Attendee1FirstName?: string;
    Attendee1LastName?: string;
    Attendee1Gender?: string;
    Attendee1DisabledText?: string;
    Attendee1Ethnicity?: string;
    SessionID: string;
    VenueID: string;
    VenueName: string;
    VenueContactID: string;
    StartDate: string;
    StartTime: string;
    TransactionID: string;
    PaymentMethod: string;
    RedemptionSourceId: string;
    RedemptionSourceType: string;
  };
}

export interface DiscountCode {
  id: string;
  accountingEntityId: string | null;
  customerId: string | null;
  discountId: string;
  value: string;
}

export interface VenueByRegion {
  ID: string;
  Name: string;
}

export interface RegionWithVenues {
  ID: string;
  Name: string;
  Venues: VenueByRegion[];
}
