const ROUTES = {
  LOGIN: '/login',
  LTA_MEMBERSHIPS: '/lta-memberships',
  SAAS_MEMBERSHIPS: '/saas-memberships',
  SAAS_MEMBERSHIPS_ADD: '/saas-memberships/add-membership',
  SAAS_MEMBERSHIP_SETTINGS: '/saas-memberships/settings',
  SAAS_MEMBERSHIP_SETTINGS_INVOICE_DETAILS: '/saas-memberships/settings/invoice-details',
  SAAS_MEMBERSHIP_SETTINGS_TERMS_AND_CONDITIONS: '/saas-memberships/settings/terms-and-conditions',
  SAAS_MEMBERSHIP_SETTINGS_STRIPE_SETUP: '/saas-memberships/settings/stripe-setup',
  MEMBERSHIPS: '/memberships',
  RANKINGS: '/rankings',
  TOURNAMENTS: '/tournaments',
  TOURNAMENT_GROUPS: '/tournaments/groups',
  TOURNAMENT_DIRECTORS: '/tournaments/directors',
  LEVEL_CONFIG: '/tournaments/level-config',
  PAYOUTS: '/tournaments/payouts-report',
  FINANCIAL_REPORTS: '/tournaments/financial-reports',
  TOURNAMENTS_REPORT: '/tournaments/tournaments-report',
  TOURNAMENT_SETTINGS: '/tournaments/settings',
  STAFF: '/staff',
  REPORTS: '/reports',
  ADD_STAFF: '/staff/add',
  PLAYERS: '/players',
  ITA_PLAYERS: '/players',
  MERGE_PLAYERS: '/merge-players',
  SUSPENSION: '/suspension',
  SUSPENSION_POINTS: '/suspension/points',
  SUSPENSION_CODES: '/suspension/codes',
  SCHOOLS: '/schools',
  PLAYER_POINTS: '/player-points',
  RANKING_REPORTS: '/reports/ranking-reports',
  NOT_FOUND: '/404',
  ORGANISATIONS: '/organisations',
  MANAGE_SCHOOLS: '/manage-schools',
  MANAGE_ORGANISATIONS: '/organisations/manage-organisations',
  ORGANISATIONS_CHECKLISTS: '/organisations/checklists',
  AREAS: '/areas',
  AREAS_COORDINATORS: '/areas/manage-coordinators',
  LEAGUES: '/leagues',
  LEAGUES_COORDINATORS: '/leagues/leagues-coordinators',
  PROGRAMMES: '/programmes',
  EVENTS: '/events',
  LEAGUES_LEVEL_CONFIG: '/leagues/level-config',
  COACHES: '/coaches',
  COACHES_CHECKLISTS: '/coaches/checklists',
  DISCOUNTS: '/discounts',
};

export default ROUTES;
