import { TFunction } from 'react-i18next';
import { ColumnDefinition } from 'src/components/admin-table/hooks';
import { ViewingContext } from 'src/components/organisations/organisation-profile/config';
import { Checklist } from 'src/types/classic';
import { GetTenantChecklists } from 'src/types/classic-queries';

interface FiltersConfig {
  search?: { transKeyPlaceholder?: string };
  checklist?: {
    defaultTag?: string;
  };
  region?: { insertAtIndex?: number; transKeyDefaultOption: string; visibility?: ViewingContext[] };
}

export enum ChecklistType {
  ORGANISATION = 'ORGANISATION',
  USER = 'USER',
}

export type ChecklistsTableItem<T extends ChecklistType> = Checklist<T> & { id: string };

export interface ChecklistsListProps {
  checklistType: ChecklistType;
  tableId: string;
  transKeys: {
    title: string;
    errorLoadingDataTable: string;
  };
  columnsConfig: ColumnDefinition[];
  filtersConfig: FiltersConfig;
}

export type CreateConfigOptions = {
  t: TFunction;
  filtersConfig?: FiltersConfig;
  viewingContext?: ViewingContext;
  tenantChecklistData?: GetTenantChecklists;
  selectedFacilityId: string;
};

export type ConfigurableChecklistsListProps = Partial<Omit<ChecklistsListProps, 'checklistType' | 'tableId'>>;
export type OrgChecklistsPageConfig = ConfigurableChecklistsListProps;
export type CoachChecklistsPageConfig = ConfigurableChecklistsListProps;
