import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  type Operation,
} from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/gatsby';
import { RestLink } from 'apollo-link-rest';
import fetch from 'isomorphic-fetch';
import { getClientConfig, getEnvConfig, getRootProviderId } from 'src/config/config';
import {
  HEADER_ORGANISATION_ID,
  HEADER_PROVIDER_ID,
  HEADER_ROOT_PROVIDER_ID,
  HEADER_STRIPE_ACCOUNT_ID,
} from 'src/constants/auth-headers';
import { resetUserSession } from 'src/utils/auth/AuthContext';
import createAuthLink from 'src/utils/auth/createAuthLink';
import { retrieveDefaultFacility } from 'src/utils/storage/local-storage';

import { getOrgId, setSelectedFacility } from './local-state';

const resetOnAuthError = (e: ErrorResponse, client: ApolloClient<any>) => {
  const { networkError: ne } = e;
  if (ne && (('statusCode' in ne && ne.statusCode === 401) || ne.message === 'Failed to fetch')) {
    console.log('user auth error, logging out');
    resetUserSession(client, window.location.pathname);
  }
};

const setRootProvider = new ApolloLink((operation, forward) => {
  if (getClientConfig().noRootProvider) return forward(operation);
  operation.setContext(({ headers = {} }) => {
    const rootProviderId = getRootProviderId();
    return { headers: { ...headers, [HEADER_ROOT_PROVIDER_ID]: rootProviderId } };
  });
  return forward(operation);
});

const setStripeAccountId = new ApolloLink((operation, forward) => {
  const noStripeAccount = !getClientConfig().stripeAccountId;
  if (noStripeAccount) return forward(operation);
  operation.setContext(({ headers = {} }) => {
    const stripeAccountId = getClientConfig().stripeAccountId;
    return { headers: { ...headers, [HEADER_STRIPE_ACCOUNT_ID]: stripeAccountId } };
  });
  return forward(operation);
});

const setProvider = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    if (getClientConfig().isSaaS) return forward(operation);
    const orgId = getOrgId(meshGatewayClient);
    return {
      headers: {
        ...headers,
        [HEADER_PROVIDER_ID]: orgId,
        [HEADER_ORGANISATION_ID]: orgId,
      },
    };
  });
  return forward(operation);
});

const setSentryGqlScope = (scope: Sentry.Scope, operation: Operation) => {
  scope.setTransactionName(operation.operationName);
  scope.setLevel('error' as Sentry.SeverityLevel);
  scope.setExtra('query', operation.query.loc?.source.body);
  scope.setExtra('variables', JSON.stringify(operation.variables));
};

const errorLink = ({ graphQLErrors, networkError, forward, operation }: ErrorResponse, client: ApolloClient<any>) => {
  if (graphQLErrors) {
    for (const error of graphQLErrors) {
      Sentry.withScope((scope) => {
        setSentryGqlScope(scope, operation);
        Sentry.captureMessage(error.message);
      });
    }
  } else if (networkError) {
    Sentry.withScope((scope) => {
      setSentryGqlScope(scope, operation);
      Sentry.captureException(networkError);
    });
  }
  resetOnAuthError({ graphQLErrors, networkError, forward, operation }, client);
};

export const tournamentsClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: from([
    onError((r) => errorLink(r, tournamentsClient)),
    setRootProvider,
    createAuthLink(),
    new HttpLink({
      uri: getEnvConfig().TOURNAMENTS_GQL_URL,
      fetch,
    }),
  ]),
  resolvers: {},
  cache: new InMemoryCache({
    typePolicies: {
      LevelConfiguration: {
        keyFields: ['contextId', 'levelId'],
      },
      FeatureSettings: {
        keyFields: ['featureId'],
      },
    },
  }),
  connectToDevTools: true,
});

export const socialLeaguesClient = new ApolloClient({
  link: from([
    onError((r) => errorLink(r, socialLeaguesClient)),
    createAuthLink(),
    new HttpLink({
      uri: getEnvConfig().SOCIAL_LEAGUES_SWIFT_GQL_URL,
      credentials: 'include',
      fetch,
    }),
  ]),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

export const microservicesClient = new ApolloClient({
  link: from([
    onError((r) => errorLink(r, microservicesClient)),
    setRootProvider,
    createAuthLink(),
    setProvider,
    new HttpLink({
      uri: getEnvConfig().GATEWAY_GQL_URL,
      fetch,
      credentials: 'include',
    }),
  ]),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

export const paymentClient = new ApolloClient({
  link: from([
    onError((r) => errorLink(r, paymentClient)),
    setRootProvider,
    createAuthLink(),
    setStripeAccountId,
    new HttpLink({
      uri: getEnvConfig().PAYMENT_GQL_URL,
      fetch,
    }),
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      Report: {
        keyFields: ['reportId'],
      },
    },
  }),
  connectToDevTools: true,
});

export const meshGatewayClient = new ApolloClient({
  link: from([
    onError((r) => errorLink(r, meshGatewayClient)),
    setRootProvider,
    createAuthLink(),
    setProvider,
    createHttpLink({ uri: getEnvConfig().MESH_GATEWAY_GQL_URL }),
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      desk_OrgConfig: {
        keyFields: ['configType', 'organisationId'],
      },
      desk_OrgConfigData: {
        keyFields: ['organisationId'],
      },
      Member: {
        keyFields: ['memberId'],
      },
    },
  }),
  connectToDevTools: true,
});

export const itfGatewayClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: from([
    onError((r) => errorLink(r, itfGatewayClient)),
    createAuthLink(),
    new HttpLink({
      uri: getEnvConfig().ITF_GQL_URL,
      fetch,
    }),
  ]),
  resolvers: {},
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

export const devRankingClient = new ApolloClient({
  link: from([
    onError((r) => errorLink(r, devRankingClient)),
    createAuthLink(),
    setProvider,
    createHttpLink({ uri: 'http://localhost:4006/graphql' }),
  ]),
  cache: new InMemoryCache(),
});

export const discountsClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([
    onError((r) => resetOnAuthError(r, discountsClient)),
    createAuthLink(),
    setProvider,
    new RestLink({
      uri: getEnvConfig().DISCOUNTS_SERVICE_URL ?? '',
      customFetch: fetch,
      credentials: 'same-origin',
    }),
  ]),
});

export const classicRestClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([
    onError((r) => resetOnAuthError(r, classicRestClient)),
    new RestLink({
      uri: getEnvConfig().CLUBSPARK_CLASSIC_URL ?? '',
      customFetch: fetch,
      credentials: 'include',
    }),
  ]),
});

const initDefaultClient = () => {
  const defaultClient = meshGatewayClient;
  const defaultFac = retrieveDefaultFacility();
  if (defaultFac) {
    setSelectedFacility(defaultFac, defaultClient);
  }

  return defaultClient;
};

export default initDefaultClient;
