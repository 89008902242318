import { ChecklistType } from 'src/components/checklists/types';
import { getRootProviderId } from 'src/config/config';
import { ChecklistApprovalType, ChecklistItem, ChecklistStatus } from 'src/types/classic';

import { ChecklistIdInput } from './types';

export const makeTypeBasedVenueChecklistParams = ({ identifier, type }: ChecklistIdInput) => {
  switch (type) {
    case ChecklistType.ORGANISATION:
      return { VenueID: identifier, EntityID: null };
    case ChecklistType.USER:
      return { VenueID: getRootProviderId(), EntityID: identifier };
    default:
      return {};
  }
};

export function canBeReviewed(approvalType: number | undefined, status: ChecklistStatus | undefined) {
  return (
    approvalType === ChecklistApprovalType.MANUAL &&
    (status === ChecklistStatus.AWAITING_REVIEW ||
      status === ChecklistStatus.REJECTED ||
      status === ChecklistStatus.COMPLETE)
  );
}

export function calculateCompletion(checklistItems: ChecklistItem[] = []): number {
  if (!checklistItems?.length) return 0;

  let completedItems = 0;

  for (const item of checklistItems) {
    if (item.Status === ChecklistStatus.COMPLETE) {
      completedItems++;
    }
  }

  return Math.round((completedItems / checklistItems.length) * 100);
}

export const convertBytesToKB = (bytes: number | undefined): number => {
  if (!bytes) return 0;
  return Math.round(bytes / 1000);
};
