import { ApolloClient } from '@apollo/client';

export const isBrowser = typeof window !== 'undefined';

export interface UserVenue {
  VenueID: string;
  Name: string;
  VenueContactID: string;
  ChooseVenueInSchemes: boolean;
  VenueSystemRoles: number;
  Scope: number;
  ModuleStatuses: { Module: number; Status: number }[];
}

export enum LoginError {
  NONE,
  GENERIC,
  NON_ADMIN,
  NO_USER,
  INVALID_SCOPE,
  UNAUTHENTICATED,
  TOO_MANY_ATTEMPTS,
}

export interface LoginProps {
  client: ApolloClient<object>;
  redirectUrl?: string;
}

export interface LoginNavState {
  redirectUrl?: string;
}
