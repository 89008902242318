import { ApolloLink, from } from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { retrieveToken } from 'src/utils/storage/local-storage';

import { resetUserSession } from './AuthContext';
import authService from './AuthService';
import { makeCsTokenHeader } from './headers';

const createAuthLink = () => {
  const setTokenLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => {
      const token = retrieveToken();
      return {
        headers: {
          ...headers,
          authorization: token ? makeCsTokenHeader(token) : '',
        },
      };
    });
    return forward(operation);
  });

  const resetOnAuthError = (errorResponse: ErrorResponse) => {
    const { networkError } = errorResponse;
    if (networkError && networkError.statusCode === 401) {
      console.log('Unauthorized, logging out');
      authService.resetAuth();
      resetUserSession();
    }
  };

  const errorLink = onError(resetOnAuthError);

  return from([errorLink, setTokenLink]);
};

export default createAuthLink;
