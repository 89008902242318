class AuthService {
  private token: string | null = null;

  setToken(token: string | null) {
    this.token = token;
  }

  getToken() {
    return this.token;
  }

  resetAuth() {
    this.token = null;
  }
}

const authService = new AuthService();
export default authService;
