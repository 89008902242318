import { ChecklistIdInput } from 'src/components/organisations/organisation-profile/panels/common/checklists/types';
import { makeTypeBasedVenueChecklistParams } from 'src/components/organisations/organisation-profile/panels/common/checklists/utils';
import { getEnvConfig } from 'src/config/config';
import { GetTournamentForEmailTemplate_tournament as Tournament } from 'src/graphql-types/GetTournamentForEmailTemplate';

import { makeCsTokenHeader } from './auth/headers';
import { usesProviderSso } from './helper/clients';
import { retrieveToken } from './storage/local-storage';
import { getBaseUrl } from './url-management';

export const TOURNAMENT_APRROVAL_TEMPLATE_FREE_TEXT = '{{free_text_field}}';

export const fetchWithAuth: typeof fetch = (input: RequestInfo | URL, init: RequestInit | undefined = {}) => {
  const token = retrieveToken();
  const headers = {
    ...(init?.headers ?? {}),
    ...(usesProviderSso() && token ? { authorization: makeCsTokenHeader(token) } : {}),
  };
  return fetch(input, {
    ...init,
    headers,
    credentials: init?.credentials || 'include',
  });
};

export const fetchTournamentApprovalEmailTemplate = async (
  t: Tournament,
  accepted: number,
  rejected: number,
  fee?: number | null,
  user?: any,
  orgName?: string,
) => {
  if (t?.primaryLocation) {
    const adminName = user?.FirstName && user?.LastName && `${user.FirstName} ${user.LastName}`;
    const res = await fetchWithAuth(
      `${getEnvConfig().CLUBSPARK_CLASSIC_EMAIL_URL}/Emails/VenueContactMessageWithTemplate?venueId=${
        t.primaryLocation.id
      }`,
      {
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({
          Name: 'TournamentApproval',
          Properties: {
            FreeText: TOURNAMENT_APRROVAL_TEMPLATE_FREE_TEXT,
            TournamentDirectorFirstName: t.director?.firstName,
            NumberRejectedEvents: `${rejected}`,
            NumberApprovedEvents: `${accepted}`,
            UstaSection: orgName,
            Level: t.level?.name,
            TournamentName: t.name,
            TournamentStartDate: t.timings.startDate,
            TournamentEndDate: t.timings.endDate,
            TournamentPrimaryLocation: t.primaryLocation.name,
            TournamentUrl: `${getEnvConfig().HOSTING_URL ?? ''}/tournaments/${t.id}`,
            SectionAdminApproverName: adminName,
            DefaultPhone: user?.PhoneNumber ?? 'N/A',
            DefaultEmail: user?.EmailAddress,
            SanctionFee: `${fee ?? 0}`,
          },
        }),
      },
    );
    if (res.ok) {
      return res.text();
    }
  }
};

export const fetchUserVenues = () => {
  return fetchWithAuth(`${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v2/User/GetCurrentUserVenues/15`);
};

export const fetchCurrentUser = () => {
  return fetchWithAuth(`${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v2/User/GetCurrentUser`);
};

export const forgotPassword = async (email: string) => {
  return await fetch(`${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v0/account/ForgottenPassword`, {
    method: 'POST',
    body: JSON.stringify({ EmailAddress: email }),
    headers: { 'content-type': 'application/json' },
  });
};

export const getVenueAdmins = async ({
  orgId,
  limit,
  offset,
  sortingOrder,
  sortingDirection,
}: {
  orgId: string;
  limit?: number;
  offset?: number;
  sortingOrder?: string;
  sortingDirection?: number;
}) => {
  const info = await fetchWithAuth(
    `${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v1/Administrators/GetVenueAdministratorsPaged`,
    {
      method: 'POST',
      body: JSON.stringify({
        queryParametersPaged: {
          sorts: [
            {
              sortDirection: sortingDirection ?? 0,
              property: sortingOrder ?? 'FirstName',
            },
          ],
          limit: limit,
          offset: offset,
        },
        venueId: orgId,
        venueRoleSubCategory: '3', // 3 = staff
      }),
      headers: { 'content-type': 'application/json' },
    },
  );

  if (info.ok) {
    return info.json();
  } else {
    throw new Error('Error fetching venue admins');
  }
};

export const getAreaCoordinators = async (
  orgId: string,
  limit?: number,
  offset?: number,
  sortingOrder?: string,
  sortingDirection?: number,
) => {
  const info = await fetchWithAuth(
    `${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v1/Administrators/GetAllVenueAdministratorsPaged`,
    {
      method: 'POST',
      body: JSON.stringify({
        queryParametersPaged: {
          sorts: [
            {
              sortDirection: sortingDirection ?? 0,
              property: sortingOrder ?? 'FirstName',
            },
          ],
          limit: limit,
          offset: offset,
        },
        venueId: orgId,
        getChildrenLevel: 1,
        venueSystemRoles: 5120,
        childVenueCategories: [30],
      }),
      headers: { 'content-type': 'application/json' },
    },
  );

  return info.json();
};

interface AddVenueAdminInput {
  orgId: string;
  externalId?: string;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  scope: number;
  invitationRedirectUrl?: string;
  venueSystemRoles: number;
}

export const addVenueAdmin = async ({
  orgId,
  externalId,
  scope,
  venueSystemRoles,
  firstName,
  lastName,
  emailAddress,
  invitationRedirectUrl,
}: AddVenueAdminInput) => {
  const redirectUrl = invitationRedirectUrl ?? getBaseUrl();
  const contactInfo = externalId
    ? { ExternalID: externalId }
    : { FirstName: firstName, LastName: lastName, EmailAddress: emailAddress };

  const res = await fetchWithAuth(`${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v0/Tournaments/AddVenueContactWithRoles`, {
    method: 'POST',
    body: JSON.stringify({
      InviteContact: true,
      SendInvitationEmail: true,
      InvitationRedirectUrl: redirectUrl,
      VenueContact: {
        ...contactInfo,
        VenueID: orgId,
        VenueSystemRoles: venueSystemRoles,
        VenueRoles: ['Staff'],
        Scope: scope,
      },
    }),
    headers: { 'content-type': 'application/json' },
  });
  const info = res.ok && (await res.json());
  if (!info) {
    throw Error('Error adding venue admin');
  }
  return info;
};

export interface BulkUploadContact {
  FirstName?: string;
  LastName?: string;
  EmailAddress?: string;
  SectionCode: string;
  Role: number;
  VenueRoles: ('Tournament Director' | 'Staff')[];
  ExternalId?: string;
  Scope?: number;
}

export interface GetOrganisationMembership {
  UAID: string;
  Name: string;
  MembershipStatus: string;
  MembershipExpiryDate: string;
  ContactName: string;
  OrgType: {
    Code: string;
    Name: string;
  };
  OrgStatus: string;
}

export const bulkAddVenueAdmins = async (contacts: BulkUploadContact[]) => {
  const res = await fetchWithAuth(
    `${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v1/VenueAdminContacts/BulkAddVenueContactWithRoles`,
    {
      method: 'POST',
      body: JSON.stringify({ VenueContacts: contacts, RedirectUrl: getBaseUrl() }),
      credentials: 'include',
      headers: { 'content-type': 'application/json' },
    },
  );
  if (res.ok) {
    return true;
  }
  throw Error('Error adding contacts');
};

export const searchSectionDistrict = async (term: string): Promise<any[] | undefined> => {
  const res = await fetchWithAuth(
    `${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v2/Venue/GetAutoCompleteVenues?term=${encodeURIComponent(
      term,
    )}&adminMode=true&globalMode=true&category=Region`,
  );
  if (res.ok) {
    return await res.json();
  }
};

export const getOrganizationMembership = async (venueId: string): Promise<GetOrganisationMembership | undefined> => {
  const res = await fetchWithAuth(
    `${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v2/Venue/GetOrganizationDetails/${encodeURIComponent(venueId)}`,
  );
  if (res.ok) {
    return await res.json();
  }
};

export const fetchRegionVenues = async () => {
  const res = await fetchWithAuth(`${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v2/Venue/GetAllVenues?category=Region`);
  if (res.ok) {
    return await res.json();
  }
};

export const fetchVenues = async () => {
  const res = await fetchWithAuth(`${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v2/Venue/GetAllVenues?category=Venue`);
  if (res.ok) {
    return await res.json();
  }
};

export const fetchFacilities = async () => {
  const res = await fetchWithAuth(
    `${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v2/Venue/GetAllVenues?category=Location&category2=Facility`,
  );
  if (res.ok) {
    return await res.json();
  }
};

export const fetchNationalVenue = async () => {
  const res = await fetchWithAuth(`${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v2/Venue/GetVenueByExternalID?id=n00`);
  if (res.ok) {
    return await res.json();
  }
};

export interface VenueContact {
  ID: string;
  Role: number;
  Scope: number;
  ReplaceCurrentRoles: boolean;
}

export const addVenueContactSystemRole = async (venueContact: VenueContact): Promise<{ Contact: VenueContact }> => {
  const res = await fetchWithAuth(
    `${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v0/ControlCentre/AddVenueContactSystemRole`,
    {
      method: 'POST',
      body: JSON.stringify(venueContact),
      headers: { 'content-type': 'application/json' },
    },
  );

  if (res.ok) {
    return res.json();
  } else {
    throw new Error('Failed to add venue contact system role');
  }
};

export const getVenueContact = async (contactId: string) => {
  const res = await fetchWithAuth(
    `${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v1/VenueAdminContacts/GetVenueContact/${contactId}`,
    {
      method: 'GET',
      credentials: 'include',
      headers: { 'content-type': 'application/json' },
    },
  );
  if (res.ok) {
    return res.json();
  } else {
    throw new Error('Failed to fetch venue contact');
  }
};

export type StripeAccountDetails = {
  Status?: string | undefined;
  Owner?: string | undefined;
  Email?: string | undefined;
  CreationDate?: string | undefined;
};

export const fetchStripeAccountDetails = async (
  urlSegment: string | undefined,
  accountId: string | undefined,
): Promise<StripeAccountDetails | undefined> => {
  const res = await fetchWithAuth(
    `${
      getEnvConfig().CLUBSPARK_CLASSIC_URL
    }/${urlSegment}/Admin/Payment/GetStripeAccountDetails?accountId=${accountId}`,
  );
  if (res.ok) {
    return await res.json();
  } else {
    throw new Error('Failed to fetch Stripe account details');
  }
};

export type VenueStripeAccount = {
  ID: string;
  Tag: string;
  UserID: string;
};

export type VenueDetails = {
  ID: string;
  Name: string;
  UrlSegment: string;
  StripeAccounts: VenueStripeAccount[];
};

export const fetchVenue = async (venueId: string): Promise<VenueDetails | undefined> => {
  const res = await fetchWithAuth(`${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v2/Venue/GetVenue?Id=${venueId}`);
  if (res.ok) {
    return await res.json();
  } else {
    throw new Error('Failed to fetch venue data');
  }
};

export const setVenueChecklistState = async ({
  idInput,
  checklistTag,
  status,
  noteContent,
  notifyByEmail,
}: {
  idInput: ChecklistIdInput;
  checklistTag: string | undefined;
  status: string;
  noteContent: string;
  notifyByEmail: boolean;
}) => {
  const res = await fetchWithAuth(`${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v0/Checklists/SetVenueChecklistState`, {
    method: 'POST',
    body: JSON.stringify({
      ...makeTypeBasedVenueChecklistParams(idInput),
      ChecklistTag: checklistTag,
      Status: status,
      Note: noteContent,
      Notify: notifyByEmail,
    }),
    headers: { 'content-type': 'application/json' },
  });

  if (res.ok) {
    return res.status;
  } else {
    throw new Error('Failed to set checklist state');
  }
};

export const setVenueChecklistItemState = async ({
  idInput,
  checklistItemTag,
  status,
  noteContent,
  notifyByEmail,
}: {
  idInput: ChecklistIdInput;
  checklistItemTag: string | undefined;
  status: string;
  noteContent: string | undefined;
  notifyByEmail: boolean;
}) => {
  const res = await fetchWithAuth(`${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v0/Checklists/SetVenueChecklistItemState`, {
    method: 'POST',
    body: JSON.stringify({
      ...makeTypeBasedVenueChecklistParams(idInput),
      ChecklistItemTag: checklistItemTag,
      Status: status,
      Note: noteContent,
      Notify: notifyByEmail,
    }),
    headers: { 'content-type': 'application/json' },
  });

  if (res.ok) {
    return res.status;
  } else {
    throw new Error('Failed to set checklist item state');
  }
};
